<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">Peplink Bandwidth</h3>
		</div>
		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<template #default>
					<dygraphs v-if="dyData.length > 0" :data="dyData" :options="dyOpts" />
				</template>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
import { KMG } from '@/helpers';

export default {
	name: 'PeplinkBandwidth',
	props: ['id', 'name', 'properties','updated'],
	data() {
		return {
			loaded: false,
			dyData: [],
			dyOpts: {},
			groupID: 1,
			wanID: 0
		};
	},
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
	},
	methods: {
		load() {
			this.loaded = false;
			this.$http.get(`peplink/bandwidth/${this.groupID}/${this.wanID}/${this.id}`).then((resp) => {
				if (
					resp.data.data &&
					resp.data.data.bandwidth &&
					resp.data.data.bandwidth.data &&
					resp.data.data.bandwidth.data.usages
				) {
					this.dyData = resp.data.data.bandwidth.data.usages.map((o) => {
						return [new Date(o.ts), parseFloat(o.down.toFixed(2)), parseFloat(o.up.toFixed(2))];
					});
				}

				this.dyOpts = {
					includeZero: true,
					labelsKMG2: false,
					labelsSeparateLines: false,
					fillGraph: true,
					connectSeparatedPoints: true,
					labels: ['Timestamp', 'Upstream', 'Downstream'],
				};
				(this.dyOpts.axes = {
					y: {
						valueFormatter: function (y, opts, series) {
							return KMG(y * 1000000) + 'B';
						},
						axisLabelFormatter: function (y, opts, series) {
							return KMG(y * 1000000) + 'B';
						},
					},
				}),
					(this.loaded = true);
			});
		},
	},
	watch: {
		updated() {
			this.load();
		},
	},
	mounted() {
		this.load();
	},
};
</script>

<style>
</style>